@mixin mediaS {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin mediaL {
  @media (min-width: 721px) {
    @content;
  }
}

@mixin mediaXL {
  @media (min-width: 1600px) {
    @content;
  }
}
