@import './viewport';
@import './mediaQuery';

body {
  margin: 0;
  font-size: vw(14);
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

input,
textarea,
button {
  outline: none;
}

.show-pc {
  @include mediaL {
    display: block;
  }
}

.hide-pc {
  @include mediaL {
    display: none;
  }
}

.show-mobile {
  @include mediaS {
    display: block;
  }
}

.hide-mobile {
  @include mediaS {
    display: none;
  }
}
